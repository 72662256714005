@import "../variables.scss";

div.parameterSettings {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;

    .parameter-options {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    div:first-of-type {
        margin-bottom: 5px;
    }

    .desc {
        margin-bottom: 10px;
        font-size: 1.1em;
    }

    .label {
        margin: 0 10px;
    }
}


.line-chart {
    width: 75%;
}

.chartContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

 /* Medium & small devices */
 @media (min-width: #{$media-xxlarge}) {
     .line-chart {
        width: 50%;
    }
    div.parameterSettings {
        flex-direction: row;
    }
}

@media (max-width: #{$media-xlarge}) {
	.line-chart {
        width: 50%;
    }
    .chartContainer {
        flex-direction: row;
    }
    div.parameterSettings {
        flex-direction: row;
    }
}

@media (max-width: #{$media-medium}) {
    .line-chart {
        width: 75%;
    }
    .chartContainer {
        flex-direction: column;
    }
    div.parameterSettings {
        flex-direction: column;
    }
}