@import "../variables.scss";

/* Tooltips */
.tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
        visibility: hidden;
        background-color: $dark-1;
        text-align: center;
        padding: 5px;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        -webkit-box-shadow: 3px 3px 3px 0px $dark-3-transparent-75;
        -moz-box-shadow: 3px 3px 3px 0px $dark-3-transparent-75;
        box-shadow: 3px 3px 3px 0px $dark-3-transparent-75;
        top: -2px;
        left: 2px;
        cursor: default;
    }

    &:hover .tooltiptext {
        visibility: visible;
    }

    &.icon {
        top: 4px;
        left: 4px;
    }
}