@import "../variables.scss";

footer {
    flex-shrink: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
    background-color: $dark-1;
    min-height: $footer-height;
    border-top: 2px solid $dark-2;

    ul {
        margin: 0px;
        li {
            display: inline;
            list-style-type: none;
            margin-right: 10px;
        }
    }

}