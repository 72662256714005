@import "../variables.scss";

.guide .container {
    flex-direction: column;
    padding: 5px;

    button
    {
        &.expand {
            right: 5px;
            position: absolute;
        }
    }

    .row {
        justify-content: center;
        &.text {
            padding: 0 40px;
        }
    }

    ul {
        padding: 0 5px;
        li {
            float: left;
            list-style: disc;
            font-size: 20px;
            width: 20px;
            list-style-position: inside;
            position: relative;
            left: 7px;
            top: -3px;
            cursor: pointer;
            line-height: 12px;
            &.highlight {
                font-size: 28px;
            }
        }
    }
    p {
        margin: 5px 0;
    }
}

// Media queries

@media (max-width: #{$media-xlarge}) {
	.guide .row {
		display: flex;
	}
}

@media (max-width: #{$media-large}) {
	.guide .row {
		display: flex;
	}
}

@media (max-width: #{$media-small}) {
	.guide .row {
		display: flex;
	}
}