@import "../variables.scss";

#svg-tile {
    svg {
        width: 100%;
    }

    text {
        &.label {
            text-transform: uppercase;
            fill: $light-0;
            font-size: 12px;
        }
        &.label-tr {
            text-transform: uppercase;
            fill: $light-0;
            font-size: 12px;
            text-anchor: middle;

            &.split-val {
                fill: $light-0;
                &.highlight {
                    fill: $dark-0;
                }
            }
        }
    }

    .neuron
    {
        fill: $light-2-transparent-90;
        &.highlight {
            fill: $white;
            stroke: $white;
            stroke-width: 3px;
        }
    }

    .treeg:hover > .node{
        stroke: #999;
        stroke-opacity: .6;
        stroke-width: 2px;
    }

    .treeg{
        cursor: default;
    }

    .link {
        stroke: #999;
        stroke-opacity: .7;
        stroke-dasharray: 9 1;
        stroke-dashoffset: 1;
        /*-webkit-animation: 0.5s linear 0s infinite flowing;*/

        &.tree-hover{
            stroke: #64C9FA;
            stroke-width: 2px;
        }

        &.positive {
            stroke: $ipa-green;
        }
        &.negative {
            stroke: #FFC78C
        }
    }

    .link-hover {
        stroke-width: 8;
        stroke: $black;
        fill: none;
        opacity: 0;
    }
}