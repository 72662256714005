@import "../variables.scss";

p.section-head {
    text-transform: uppercase;
    letter-spacing: 0.17em;
    line-height: 1.2em;
    font-weight: 400;
    margin: 0;
    color: $light-0;
    padding: 5px;
    border-bottom: 2px solid $ipa-green;
    margin-bottom: 5px;
}

.tileContainer {
    width: 100%;
}