@import "variables.scss";

/* Site */
*, *:before, *:after {
    box-sizing: inherit;
}

html {
	box-sizing: border-box;
	color: $light-0;
	background-color: $dark-0;
    font-size: 0.9em;
    height: 100%
}

body {
	/*font-family: Raleway, sans-serif;*/
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#root {
    // min-height: 100vh;
    height: 100%;
    position: relative;
}

.content {
    margin-left: auto;
    margin-right: auto;
    //height: 100%;
    min-height: calc(100% - #{$footer-height});
    flex: 1 0 auto;
}

/* Visibilities */
.hidden {
    display: none!important;
}

.invisible {
    visibility: hidden;
}

/* Texts */
.bold {
	font-weight: 500
}

/* floats */
.float-left {
	float: left;
}

.float-right {
	float: right;
}

/* margins */
.ml-10 {
	margin-left: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.mlr-10 {
	margin: 0 10px;
}

.mb-10 {
	margin-bottom: 10px;
}

/* row & container */
.row {
	width: 100%;
    display: flex;
    position: relative;

    &.full-height {
        height: calc(100vh - #{$header-height} - #{$footer-height});
    }

    &.center {
        align-items: center;
        justify-content: center;
    }
}

.container:after,
.container:before,
.row:after,
.row:before {
    content: "";
    display: table;
    clear: both;
}

.container {
	padding: 1em;
	margin: 10px;
	display: flex;
	justify-content: space-between;
    position: relative;
    
    &.dark-background {
        background-color: $dark-1;
        border-radius: 8px;
    }

    div {
        text-align: center;
        align-items: center;
    }

    &.c-1-1 {
        width: calc(100% - 20px);
        float: left;
    }   
    &.c-1-3 {
        width: calc(33.3% - 20px);
        float: left;
    }
    &.c-2-3 {
        width: calc(66.6% - 20px);
        float: left;	
    }
    &.c-1-4 {
        width: calc(24.9% - 20px);
        float: left;
    }
    &.c-1-2 {
        width: calc(49.9% - 20px);
        float: left;
    }
    &.c-3-4 {
        width: calc(74.9% - 20px);
        float: left;
    }
}

/* Positions */
.upper-right {
    position: absolute;
    right: 5px;
    top: 2px;
}

 /* Medium & small devices */
@media (max-width: #{$media-xlarge}) {
	.row {
        display: block;
        &.full-height {
            display: flex;
        }
	}
	.container:not(.c-sm) {
		width: calc(100% - 20px);	
	}
}

 /* small devices */
 @media (max-width: #{$media-large}) {
	// .row {
	// 	display: block;
	// }
	.container.c-1-2 {
		width: calc(100% - 20px);
	}

	.container.c-1-4 {
		width: calc(49.9% - 20px);
	}
}

@media (max-width: #{$media-small}) {
	// .row {
	// 	display: block;
	// }
	.container {
		width: calc(100% - 20px)!important;	
	}
}

/* Buttons */
button {
    cursor: pointer;
}

/* Icons */
.icon {
    background: none;
    border: none;

    &:focus {
        outline: none;
    }
}

/* Rules for sizing the icon. */

.material-icons,
.material-icons-outlined {
    &.md-18 { font-size: 18px; }
    &.md-24 { font-size: 24px; }
    &.md-36 { font-size: 36px; }
    &.md-48 { font-size: 48px; }

    &.md-dark {
        color: $dark-0;
        &.md-inactive { color: $dark-0-transparent-30; }
    }

    &.md-light {
        color: $light-0;
        &.md-inactive { color: $light-0-transparent-30; }
    }
}

/* Select */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    background: $light-0;
    border: 1px solid $light-1;
    border-radius: 5px;
    padding: 4px;
    width: 100%;
    font-size: 14px;
    color: $dark-0;
    outline: none;
    margin-right: 15px;

    &:hover {
        cursor: pointer;
        border-color: $ipa-green;
    }
    &::-ms-expand {
        display: none;
    }
    &:focus::-ms-value {
        background-color: transparent;
    }
}

.select-wrapper {
    position: relative;
    
    &::after {
        content: "arrow_drop_down";
        color: $dark-0;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        position: absolute;
        right: 0;
        top: 4px;
        pointer-events: none;
    }
}

/* Toggle button */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $light-2;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 3px;
        background-color: $white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    &.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}

input:checked + .slider {
    background-color: $ipa-green;

    &:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
}

input:focus + .slider {
    box-shadow: 0 0 1px $ipa-green;
}

/* Tables */
div.table {
    display: flex;
    flex-direction: column;
    align-items: initial;

    .tableRow {
        margin-bottom: 10px;
        justify-content: space-between;
        display: flex;
    }
}

/* Custom scroll bar */

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $dark-0; 
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $light-2; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $dark-2; 
}

/* Color fills */
.fill-0 {
    fill: $fill-0;
    &.highlight {
        fill: $fill-0-highlight;
        stroke: $fill-0;
        stroke-width: 2;
    }
}
.fill-1 {
    fill: $fill-1;
    &.highlight {
        fill: $fill-1-highlight;
        stroke: $fill-1;
        stroke-width: 2;
    }
}
.fill-2 {
    fill: $fill-2;
    &.highlight {
        fill: $fill-2-highlight;
        stroke: $light-0;
        stroke-width: 2;
    }
}
.fill-3 {
    fill: $fill-3;
    &.highlight {
        fill: $fill-3-highlight;
        stroke: $light-0;
        stroke-width: 2;
    }
}

/* Weblinks */
a.web-link {
            
    color: $white;
    text-decoration: none;
    font-weight: 500;

    &:visited {
        text-decoration: none
    }
    &:hover {
        text-decoration: underline
    }
}