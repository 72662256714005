@import "../variables.scss";

.actionBar {
    background: $dark-1;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid $dark-3;
    -webkit-box-shadow: 3px 3px 3px 0px $dark-3-transparent-75;
    -moz-box-shadow: 3px 3px 3px 0px $dark-3-transparent-75;
    box-shadow: 3px 3px 3px 0px $dark-3-transparent-75;
    display: flex;
    justify-content: center;

    .container {
        float: none;
    }
}

.parameterInfo {
    margin-top: 10px;
    display: inline-block;

    span.infoIcon {
        float: left;
        top: 2px;
        position: relative;
    }

    p {
        float: left;
        margin: 0 5px;
    }
}

.layerNavigation {
    display: flex;
    justify-content: center;

    span {
        line-height: 1.5em;
    }
}

// Train DT
img.extraction-process {
    width: 75%;
}

// Contact
.contact {
    p {
        margin: 2px 0;
    }
    p:first-child {
        margin-top: 1em;
    }
    .web-link {
        font-weight: normal;
    }
    span {
        margin-right: 5px;
        position: relative;
        top: 4px;
    }
}
