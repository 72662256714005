// colors
$black: #000;
$black-transparent-30: rgba(0, 0, 0, 0.3);

$dark-0: #333;
$dark-0-transparent-30: rgba(51, 51, 51, 0.3);
$dark-1: #404040;
$dark-2: #4f4f4f;
$dark-3: #222;
$dark-3-transparent-75: rgba(34,34,34,0.75);
$dark-4: #2d2d2d;

$white: #fff;

$light-0: #EFEEEE;
$light-0-transparent-30: rgba(239, 238, 238, 0.3);
$light-1: #E0E0E0;
$light-2: #ccc;
$light-2-transparent-90: rgba(204, 204, 204, 0.9);

$ipa-green: #009474;

// classes (dt)
$fill-0: #009474 ;
$fill-0-highlight: #3cefc7;
$fill-1: #FF7D2C;
$fill-1-highlight: #FFC278;
$fill-2: #052A5E;
$fill-2-highlight: #4976B5;
$fill-3: #E69000;
$fill-3-highlight: #FFB02C;

// feature importances
$Paragliding-Peaches-1-hex: #FF6F5F;
$Paragliding-Peaches-2-hex: #FF9D75;
$Paragliding-Peaches-3-hex: #FFC78C;
$Paragliding-Peaches-4-hex: #FFE39F;
$Paragliding-Peaches-5-hex: #A7D2D6;


// header & footer
$header-height: 55px;
$footer-height: 45px;
$mobilenav-height: 50px;
// TODO

//widths

// fonts

// media queries
$media-small: 500px;
$media-medium: 768px;
$media-large: 850px;
$media-xlarge: 992px;
$media-xxlarge: 1400px;