@import "../variables.scss";

table {
    display: flex;
    justify-content: center;

    td {
        padding: 5px 10px;
    }

    tr:nth-child(even) {background: $dark-2}

    .hightlightRow {
        color: $dark-1;
        background: $light-0 !important;
        font-weight: 700;
    }
}