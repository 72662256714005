@import "../variables.scss";

.navigation {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    ul {
        /* width: 100%; */
        width: calc(100% - 96px);
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: nowrap; /* assumes you only want one row */
        border-bottom: 2px solid $white;
        margin: 10px 0;
        padding: 0 10px;
        float: left;

        li {
            padding: 5px 5px 15px 5px;
            position: relative;
            text-align: center;
            display: flex;
            align-items: center;
        }
    }

    .dot {
        height: 25px;
        width: 25px;
        background-color: $white;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        clear: both;
        transform: translate(-50%, 50%);
        position: absolute;
        bottom: 0;
        left: 50%;
    }

    .text-link {
        color: inherit;
        text-decoration: inherit;
    }

    .active {
        background: none;
        color: $ipa-green;
        font-weight: 500;

        .dot {
            background-color: $ipa-green;
        }
    }

    &.mobile {
        height: 50px;
        width: 100%;
        justify-content: center;
        background-color: $dark-2;;
        position: relative;

        li {
            list-style: none;
        }

        
    }
}


.nav-expand {
    height: 20px;
    
    div {
        z-index: 2;
        height: 17px;
        width: 55px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: $dark-2;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    button {
        width: 100%;
        height: 100%;
        color: $light-1;
        position: relative;
        top: -4px;
        
        &:hover {
            color: $white;
        }
    }
}

.nav-list {
    background-color: $dark-2;;
    position: absolute;
    width: 100%;
    height: calc(100vh - #{$header-height} - #{$mobilenav-height});
    z-index: 1;
    top: $mobilenav-height;

    ul{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 25px 10px 15px 10px;
        margin: 0;
        height: 100%;

        li {
            list-style-type: none;
            padding: 10px 0;
            
            a {
                color: $white;
                text-decoration: none;
            }
            &.active {
                a {
                    color: $ipa-green;
                    font-weight: 500;
                }
                background: none;
            }
        }
    }
    
}


@media (max-width: #{$media-medium}) {
    .navigation {
        font-size: 0.8em;
    }
}

@media (max-width: 600px) {
    .navigation {
        font-size: 1em;
    }
}