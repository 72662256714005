@import "../variables.scss";

.modal {
    width: 600px;
    background: $dark-0;
    border: 1px solid $dark-3;
    border-radius: 5px;
    transition: 0.5s ease-out;
    box-shadow: -2rem 2rem 2rem $black-transparent-30;
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 25%;
    padding: 10px 20px;

    &.off {
        opacity: 0;
        visibility: hidden;
        filter: blur(8px);
        transform: scale(0.33);
        box-shadow: 1rem 0 0 $black-transparent-30;
    }

    h2 {
        border-bottom: 1px solid $light-2;
        padding: 1rem;
        margin: 0;
    }

    .content {
        padding: 1rem;
    }

    button.close {
        position: absolute;
        right: 5px;
        top: 5px;
    }
  }


@supports (offset-rotation: 0deg) {
    offset-rotation: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");
    .modal.off {
      offset-distance: 100%;
    }
}

@media (prefers-reduced-motion) {
    .modal {
      offset-path: none;
    }
}