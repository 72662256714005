@import "../variables.scss";

.scrollable {
	max-height: 85%;
	overflow-y: scroll;
}

.container.tree {
	display: block;
	padding: 0;
}

#rule {
	display: none;
}

button img {
	max-width: 40px;
}

.guide {
	width: 100%;
}

.accordion {
	background-color: $dark-0;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: 0.4s;
	color: $light-0;
	text-transform: uppercase;
	font-weight: 600;
	border: 2px solid $dark-1;
	border-radius: 5px;
	margin-bottom: 5px;
	position: relative;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
	background-color: $dark-2;
}

.accordion .up {
	display: none;
}

.accordion.active + .panel {
	display: block;
}

.accordion.active .down {
	display: none;
}

.accordion.active .up {
	display: block;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
	padding: 15px;
	background-color: #393939;
	display: none;
	overflow: hidden;
	margin-bottom: 5px;
}

.container .panel * {
	text-align: left;
}

.accordion i {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
}


input[type="checkbox"] {
	display:none;
}
input[type="checkbox"] + label::before {
	width: 15px;
	height: 15px;
	border-radius: 4px;
	border: 2px solid $light-0;
	background-color: $dark-0;
	display: block;
	content: "";
	float: left;
    margin-right: 5px;
    top: 3px;
    position: relative;
}
input[type="checkbox"]:checked+label::before {
	box-shadow: inset 0px 0px 0px 3px $dark-1;
	background-color: $light-0;
}

.input-group {
    padding: 5px;
    color: $light-0;
    border-width: 2px 0;
    border-color: $dark-1;
	border-style: solid; 	
	background-color: $dark-0;
	margin-bottom: 5px;
}

.input-group:hover {
	color: $white;
	background-color: #303030;
}

.input-group:hover * {
	cursor: pointer
}

.input-wrapper {
	display: none;
	background-color: $dark-1;
	padding: 10px 5px;
	margin-bottom: 5px;
	position: relative;
	top: -5px;
}

.input-wrapper .desc {
	margin-bottom: 10px;
}

.input-wrapper.visible {
	display: block;
}

.input-wrapper img {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

div#rule {
    border: 1px solid #eee;
}

@media (max-width: 1400px) {
	.input-wrapper img {
        width: 90%;
    }
}