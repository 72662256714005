@import "../variables.scss";

.start button {
    width: 8em;
    height: 4em;
    background-color: $ipa-green;
    color: $light-0;
    border: none;
    font-size: 4em;
    text-align: center;
}

input[type=checkbox]
{
  -webkit-appearance:checkbox;
  width: 10px;
}