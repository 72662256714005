@import "../variables.scss";

/* Toolbar */
.toolbar {
	float: left;
	width: 100%;
	background-color: $ipa-green;
    height: 55px;
    
    .logo {
        position: absolute;
        right: 10px;
        height: 35px;
        top: 10px;
    }

    .logo-xai {
        position: relative;
        top: 1px;
        height: 50px;
        margin-right: 10px;
    }

    
    div.title {
        font-size: 2em;
        margin: 0 auto;
        text-align: center;
        display: table;

        span {
            display: table-cell;
            vertical-align: middle;
        }
    }
}